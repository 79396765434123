














































































































































































































































import { Getter } from 'vuex-class';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import AuthModule from '@/app/auth/store';
import { FormAction } from '@/app/core/types';
import { Currency } from '@/app/core/dto/money.dto';
import FormMixin from '@/app/core/mixins/form.mixin';
import { email, gte, required } from '@/app/core/validation';
import DatePicker from '@/app/core/components/date-picker.vue';
import TimePicker from '@/app/core/components/time-picker.vue';
import MoneyInput from '@/app/core/components/money-input.vue';
import ChannelSelect from '@/app/core/components/channel-select.vue';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import LanguageSelect from '@/app/core/components/language-select.vue';
import ApartmentSelect from '@/app/core/components/apartment-select.vue';
import ReservationDto from '../dto/reservation.dto';

const oneDay = 1000 * 60 * 60 * 24;

@Component({
  components: {
    DatePicker,
    TimePicker,
    MoneyInput,
    ChannelSelect,
    ApartmentSelect,
    LanguageSelect,
  },
})
export default class ReservationForm extends Mixins(FormMixin, HandlesErrorMixin) {
  private showArrivalDatePicker = false;

  private showDepartureDatePicker = false;

  @Getter
  private isHost!: typeof AuthModule.prototype.isHost;

  @Prop({ type: String, required: true })
  action!: FormAction;

  @Prop({ type: Object, default: null })
  reservation!: ReservationDto;

  private defaultData: ReservationDto = {
    apartment: '',
    arrival: new Date().toISOString(),
    departure: new Date().toISOString(),
    channel: null,
    guest: {},
    payment: {
      price: {
        value: 0,
        currency: Currency.USD,
      },
      prepayment: {
        value: 0,
        currency: Currency.USD,
      },
      deposit: {
        value: 0,
        currency: Currency.USD,
      },
    },
  };

  protected data: ReservationDto = this.defaultData;

  protected rules = {
    apartment: [required],
    arrival: [required],
    departure: [required],
    adults: [gte(0)],
    children: [gte(0)],
    channel: [required],
    guest: {
      firstname: [required],
      laststname: [required],
      email: [required, email],
    },
    payment: {
      price: [gte(0)],
      prepayment: [gte(0)],
      deposit: [gte(0)],
    },
  };

  get isBlocking() {
    return this.data.channel?.name === 'Blocked channel';
  }

  allowedDepartureDates(date: string) {
    return date > this.data.arrival;
  }

  @Watch('data.arrival', { immediate: true })
  adjustDeparture() {
    if (this.data.arrival >= this.data.departure) {
      const seconds = Date.parse(this.data.arrival) + oneDay;
      this.data.departure = new Date(seconds).toISOString();
    }
  }

  @Watch('reservation', { immediate: true })
  setData() {
    this.data = this.reservation || this.defaultData;
  }
}
