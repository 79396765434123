












import { Component } from 'vue-property-decorator';
import { FormAction } from '@/app/core/types';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import ReservationDto from '../dto/reservation.dto';
import ReservationForm from '../components/reservation-form.vue';
import { createReservation } from '../services/reservations.service';

@Component({
  metaInfo(this: NewReservation) {
    return { title: this.$t('pageTitle.newReservation').toString() };
  },
  components: {
    ReservationForm,
  },
})
export default class NewReservation extends HandlesErrorMixin {
  private loading = false;
  private action = FormAction.Create;

  async createReservation(dto: ReservationDto) {
    this.loading = true;

    try {
      const response = await createReservation(dto);
      const { id } = response.data;

      this.$router.push(`/reservations/details/${id}`);
      this.$notify.success(this.$t('success.reservationCreated').toString());
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }
}
